<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">

            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            VENDOR BUSINESS NAME
                        </span>
                        <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            v-b-tooltip.hover.v-dark.right.html="' This should be the media supplier name that we are placing the order with. Ie: website name, publication or station call letters'
                                ">
                            <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path
                                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </slot>
                    <b-form-input @blur="SetDirty('VendorBusinessName')" @focus="ResetDirty('VendorBusinessName')"
                        autocomplete="off" id="BusinessName" class="omniipclass" :maxlength="56"
                        v-model="MediaVendor.VendorBusinessName" placeholder="Enter Vendor Business Name"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.VendorBusinessName.$dirty && !$v.MediaVendor.VendorBusinessName.required">
                        Required field
                    </div>
                </div>

                <div class="fieldborder row mt-2">
                    <div class="col-md-6">
                        <div>
                            <label>IDENTIFICATION NUMBER</label>
                            <OmniSelect placeholder="Select Identification Code" name="Media" :showLabel="false"
                                :options="Identificationnumberslist" label="IDENTIFICATION NUMBER" @input="IDNumberchange"
                                v-model="MediaVendor.IdentificationType" />
                        </div>

                    </div>
                    <div class="col-md-6" v-show="MediaVendor.IdentificationType">
                        <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '##-########']"
                            style="margin-top:1.5rem;" class="form-control" v-if="IsUSTaxIDSelected"
                            @focus="ResetDirty(MediaVendor.IdentificationType)"
                            @blur="SetDirty(MediaVendor.IdentificationType)" autocomplete="off" id="usTaxId"
                            v-model="MediaVendor[MediaVendor.IdentificationType]"
                            :placeholder="'Enter US Tax ID'"></the-mask>
                        <!-- <b-form-input style="margin-top:1.5rem;" v-if="IsUSTaxIDSelected" @focus="ResetDirty(MediaVendor.IdentificationType)" @blur="SetDirty(MediaVendor.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(MediaVendor.IdentificationType)" v-model="MediaVendor[MediaVendor.IdentificationType]" v-numericOnly :placeholder="'Enter '+MediaVendor.IdentificationType"></b-form-input> -->
                        <!-- <b-form-input style="margin-top:1.5rem;" v-else-if="IsSocialSecurityNumberSelected" @focus="ResetDirty(MediaVendor.IdentificationType)" @blur="SetDirty(MediaVendor.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(MediaVendor.IdentificationType)" v-model="MediaVendor[MediaVendor.IdentificationType]" v-numericOnly :placeholder="'Enter '+MediaVendor.IdentificationType"></b-form-input> -->
                        <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['###', '###-##', '###-##-####']"
                            v-else-if="IsSocialSecurityNumberSelected" style="margin-top:1.5rem;" class="form-control"
                            @focus="ResetDirty(MediaVendor.IdentificationType)"
                            @blur="SetDirty(MediaVendor.IdentificationType)" autocomplete="off" id="socialsecurity"
                            :maxlength="11" v-model="MediaVendor[MediaVendor.IdentificationType]"
                            :placeholder="'Enter Social Security Number'"></the-mask>

                        <b-form-input style="margin-top:1.5rem;" v-else @focus="ResetDirty(MediaVendor.IdentificationType)"
                            @blur="SetDirty(MediaVendor.IdentificationType)" autocomplete="off" id="Foreign"
                            class="omniipclass" v-model="MediaVendor[MediaVendor.IdentificationType]"
                            :placeholder="'Enter ' + MediaVendor.IdentificationType"></b-form-input>

                        <div class="error-message-format"
                            v-if="MediaVendor.IdentificationType && $v.MediaVendor[MediaVendor.IdentificationType].$dirty">
                            <span v-show="!$v.MediaVendor[MediaVendor.IdentificationType].required">
                                Required field
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mt-2 d-block customdropzonearea row col-lg-8 mx-auto" v-show="MediaVendor.IdentificationType">
                <label for="axlocatoin" class="lbltxt">{{ AttachmentLabel }}</label>
                <span>
                    <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        v-b-tooltip.hover.v-dark.right.html="' Attachment of a completed W-9 form or Foreign W-8 form signed and dated within the last year, required for any change in ownership'
                            ">
                        <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <path
                                d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                        </g>
                    </svg>
                </span>
                <vue-dropzone v-on:vdropzone-mounted="mountedIdentitificationDropzone" :duplicateCheck="true"
                    ref="identificationDropzone" id="identificationDropzone" :useCustomSlot="true" class="customdropzone"
                    :include-styling="false" :options="dropzoneOptions"
                    v-on:vdropzone-file-added="IdentificationUploadedToDropzone"
                    v-on:vdropzone-removed-file="IdentificationRemovedFromDropzone">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h">
                            <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                            Drag and drop file here or
                            <span class="draganddropspan"> browse </span>

                        </p>
                    </div>
                </vue-dropzone>
            </div>
            <div class="d-block row col-lg-8 pt-3 mb-n3 mx-auto">
                <b-form-group label="Is this for former employee Cobra reimbursement?">
                    <b-form-radio-group id="FormerEmployeeReimbursement" v-model="MediaVendor.FormerEmployeeReimbursement" name="FormerEmployeeReimbursement">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="col-md-8 mx-auto">
                <div class="fieldborder pt-2">
                    <label>VENDOR REMITTANCE NAME</label>
                    <b-form-input @focus="ResetDirty('VendorRemittanceName')" @blur="SetDirty('VendorRemittanceName')"
                        autocomplete="off" id="VendorRemittanceName" class="omniipclass"
                        v-model="MediaVendor.VendorRemittanceName" placeholder="Enter Vendor Remittance Name"
                        :maxlength="`${this.MediaVendor.PrintValueSelected == this.MediaVendor.MediaType ? 30 : 20}`"></b-form-input>
                </div>
                <div class="fieldborder pt-2">
                    <label>COUNTRY</label>
                    <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Country"
                        name="RemittanceCountry" :showLabel="false" v-model="MediaVendor.RemittanceCountry" :options="CountryList">
                    </OmniSelect>
                </div>
                <div class="fieldborder pt-2">
                    <label>STREET ADDRESS LINE 1 </label>
                    <b-form-input @focus="ResetDirty('StreetAddressLine1')" @blur="SetDirty('StreetAddressLine1')"
                        autocomplete="off" id="StreetAddressLine1" class="omniipclass"
                        v-model="MediaVendor.StreetAddressLine1" placeholder="Enter Street Address Line1"
                        :maxlength="`${this.MediaVendor.PrintValueSelected == this.MediaVendor.MediaType ? 26 : 24}`"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.StreetAddressLine1.$dirty && !$v.MediaVendor.StreetAddressLine1.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <label>STREET ADDRESS LINE 2 </label>
                    <b-form-input @focus="ResetDirty('StreetAddressLine2')" @blur="SetDirty('StreetAddressLine2')"
                        autocomplete="off" id="StreetAddressLine2" class="omniipclass"
                        v-model="MediaVendor.StreetAddressLine2" placeholder="Enter Street Address Line2"
                        :maxlength="10"></b-form-input>
                </div>
                <div class="fieldborder pt-2" v-if="IsCountryUSA">
                    <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
                    <b-form-input @focus="ResetDirty('RemittanceCity')" @blur="SetDirty('RemittanceCity')" autocomplete="off"
                        id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="MediaVendor.RemittanceCity"
                        placeholder="Enter City" :maxlength="19"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.RemittanceCity.$dirty && !$v.MediaVendor.RemittanceCity.required">
                        Required field
                    </div>
                </div>
                <div class="row fieldborderomniipclass pt-2" v-if="IsCountryUSA">
                    <div class="col-md-8">
                        <label v-if="IsCountryUSA" for="RemittanceState" class="lbltxt">State</label>
                        <OmniSelect  :showLabel="false" class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA" placeholder="Select State" name="RemittanceState" :showSearch="true" v-model="MediaVendor.RemittanceState" :options="StateList">
                        </OmniSelect>
                    </div>
                    <div class="col-md-4">
                        <label v-if="IsCountryUSA" for="RemittanceZipCode" class="lbltxt">ZIP CODE</label>
                        <b-form-input autocomplete="off" id="RemittanceZipCode" class="" :minlength="5" v-if="IsCountryUSA" :maxlength="5" v-bind:class="{
                                redtextbox: (validationtextbox || !$v.MediaVendor.RemittanceZipCode.numeric) && $v.MediaVendor.RemittanceZipCode.$dirty,
                                notredtextbox: !validationtextbox && $v.MediaVendor.RemittanceZipCode.numeric,
                            }" @blur="SetDirty('RemittanceZipCode')" @focus="ResetDirty('RemittanceZipCode')"
                            @keyup="ValidateZipCode()" v-model="MediaVendor.RemittanceZipCode"
                            placeholder="Enter Zip Code"></b-form-input>
                        <div v-show="validationtextbox && $v.MediaVendor.RemittanceZipCode.$dirty" class="error-message-format">
                            Input must be 5 characters
                        </div>
                        <div v-show="!$v.MediaVendor.RemittanceZipCode.numeric && $v.MediaVendor.RemittanceZipCode.$dirty"
                            style="width:135px;" class="error-message-format">
                            This field can only contain numerical values
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-block row col-lg-8 mx-auto mt-2">
                <b-form-group>
                    <label>
                        Vendor Email
                    </label>
                    <b-form-input type="email" @focus="ResetDirty('RemittanceAdviceEmail')"
                        @blur="SetDirty('RemittanceAdviceEmail')" autocomplete="off" placeholder="Enter Vendor Email"
                        v-model="MediaVendor.RemittanceAdviceEmail" ref="email"></b-form-input>
                    <div class="error-message-format" v-if="$v.MediaVendor.RemittanceAdviceEmail.$dirty">
                        <span v-show="!$v.MediaVendor.RemittanceAdviceEmail.required">
                            Required field
                        </span>
                        <span v-show="!$v.MediaVendor.RemittanceAdviceEmail.email">
                            Please enter a valid email address (eg. test@finsys.com)
                        </span>

                    </div>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto">
                <b-form-group label="Did the vendor specify their URL on the form the vendor completed?">
                    <b-form-radio-group id="url-specified" v-model="MediaVendor.UrlSpecifiedInForm" name="url-specified">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row customdropzonearea col-lg-8 mx-auto" v-show="IsUrlNotSpecified">
                <label for="" v-show="IsUrlNotSpecified" class="lbltxt">A sample from the Media Kit must be attached</label>
                <vue-dropzone v-on:vdropzone-mounted="mountedSampleMediaKitDropzone" v-show="IsUrlNotSpecified"
                    :duplicateCheck="true" ref="myVueDropzone" id="dropzone" :useCustomSlot="true" class="customdropzone"
                    :include-styling="false" :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToDropzone"
                    v-on:vdropzone-removed-file="RemovedFromDropzone">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h">
                            <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                            Drag and drop file here or
                            <span class="draganddropspan"> browse </span>

                        </p>
                    </div>
                </vue-dropzone>
            </div>
            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder" style="margin-top:10px">
                    <label>Who at the vendor provided this information? </label>
                    <br />
                    <label>Name </label>
                    <b-form-input @blur="SetDirty('InfoFromName')" @focus="ResetDirty('InfoFromName')" autocomplete="off"
                        id="InfoFromName" class="omniipclass" :maxlength="36" v-model="MediaVendor.InfoFromName"
                        placeholder="Name"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.InfoFromName.$dirty && !$v.MediaVendor.InfoFromName.required">
                        Required field
                    </div>

                    <label>Title </label>
                    <b-form-input @blur="SetDirty('InfoFromTitle')" @focus="ResetDirty('InfoFromTitle')" autocomplete="off"
                        id="InfoFromTitle" class="omniipclass" :maxlength="36" v-model="MediaVendor.InfoFromTitle"
                        placeholder="Title"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.InfoFromTitle.$dirty && !$v.MediaVendor.InfoFromTitle.required">
                        Required field
                    </div>

                    <label>Phone Number </label>
                    <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '1-###-###-####']"
                        style="margin-top:1px;" class="form-control" @blur="SetDirty(MediaVendor.InfoFromPhone)"
                        @focus="ResetDirty(MediaVendor.InfoFromPhone)" autocomplete="off" id="InfoFromPhone" :maxlength="14"
                        v-model="MediaVendor.InfoFromPhone"></the-mask>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.InfoFromPhone.$dirty && !$v.MediaVendor.InfoFromPhone.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder mt-2">
                    <b-form-group label="How was this information obtained?" class="mb-n3 timeonly-text">
                        <div class="form-group" style="margin-left: 14px">
                            <div class="text-left" style="margin-top: 11px">
                                <b-form-checkbox v-model="MediaVendor.InfoFromSourceIsVerbal" :value="true" label="" inline>
                                    Verbal
                                </b-form-checkbox>
                            </div>
                        </div>

                        <div class="form-group" style="margin-left: 14px">
                            <div class="text-left" style="margin-top: 11px">
                                <b-form-checkbox v-model="MediaVendor.InfoFromSourceIsWritten" :value="true" inline>
                                    Written
                                </b-form-checkbox>
                            </div>
                        </div>
                    </b-form-group>
                </div>
                <div class="fieldborder">
                    <b-form-group class="mb-1" v-if="InfoFromSourceDisplayVerbal">
                        <div >
                            <label>Verbal </label>
                            <OmniSelect placeholder="Select Verbal" name="Media" :showLabel="false"
                                :options="InfoFromSourceVerbalOptions" label="Select Verbal"
                                v-model="MediaVendor.InfoFromSourceVerbal" />
                        </div>
                    </b-form-group>
                </div>

                <b-form-group v-if="InfoFromSourceDisplayWritten">
                    <div>
                        <label>Written</label>
                        <OmniSelect placeholder="Select Written" name="Written" :showLabel="false"
                            :options="InfoFromSourceWrittenOptions" label="Select Written"
                            v-model="MediaVendor.InfoFromSourceWritten" />
                    </div>
                    <div class="fieldborder customdropzonearea mt-2">

                        <vue-dropzone v-on:vdropzone-mounted="mountedWrittenDropzone" :duplicateCheck="true"
                            ref="DropzoneWrittenSource" id="DropzoneWrittenSource" :useCustomSlot="true"
                            class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                            v-on:vdropzone-file-added="UploadedtoDropzoneSource"
                            v-on:vdropzone-removed-file="RemovedFromDropzoneSource">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="
                                        margin-top: -7px !important;
                                        font-family: 'Open Sans';
                                        " />
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>
                                </p>
                            </div>
                        </vue-dropzone>
                    </div>
                </b-form-group>
            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                BACK
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()"
                :disabled="this.$v.$invalid || !IsVerbalOrWrittenChecked">
                NEXT
            </button>
        </div>

    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
import { TheMask } from 'vue-the-mask'
import { required, requiredIf, numeric, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import service from "../../../services/Dropdowns";
const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)

export default {
    components: {
        vueDropzone: vue2Dropzone,
        TheMask
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            WrittenAttachmentFile: 0,
            IsIdentificationFilesLoadedInEdit: false,
            IsSampleMediaKitFilesLoadedInEdit: false,
            IsWrittenFilesLoadedInEdit: false,
            Identificationnumberslist: [{
                label: "US TAX ID",
                value: "USTaxID"
            },
            {
                label: "Foreign",
                value: "Foreign"
            },
            {
                label: "Social Security",
                value: "SocialSecurityNumber"
            },
            ],
            //MediaVendor: {
            //     VendorBusinessName: null,
            //     IdentificationCode: null,
            //     IdentificationNumber: null,
            //     UrlSpecifiedInForm: null,
            //     RemittanceAdviceEmail: null
            // },
            InfoFromSourceVerbalOptions: [
                { label: "In Person", value: "In Person" },
                { label: "Phone", value: "Phone" }
            ],
            InfoFromSourceWrittenOptions: [
                { label: "Teams Chat", value: "Teams Chat" },
                { label: "Email", value: "Email" }
            ],
            InvoicesImmediatePaymentOptions: [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" }
            ],
            dropzoneOptions: {
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 5!");
                        }
                    });

                    this.on("removedfile", function () { });
                },
            },
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../../assets/Icons/file.png"),
            CountryList: [],
            StateList: [],
            CountryUnitedStatesValue: "USA - United States",
            validationtextbox: false,
        }
    },
    validations: {
        MediaVendor: {
            VendorBusinessName: {
                required: required,
            },
            IdentificationType: {
                required: required,
            },
            RemittanceAdviceEmail: {
                required: requiredIf(function () {
                    return this.MediaVendor.IsMediaRepRequest == "true" || this.MediaVendor.IsMediaRepRequest == true;
                }),
                email: EmailWithoutCase
            },
            USTaxID: {
                required: requiredIf(function (model) {
                    return model.IdentificationType == "USTaxID";
                }),
                numeric,
                minLength: minLength(9)
            },
            SocialSecurityNumber: {
                required: requiredIf(function (model) {
                    return model.IdentificationType == "SocialSecurityNumber";
                }),
                numeric,
                minLength: minLength(9),
                maxLength: maxLength(9),
            },
            Foreign: {
                required: requiredIf(function (model) {
                    return model.IdentificationType == "Foreign";
                })
            },
            UrlSpecifiedInForm: {
                required: required
            },
            IdentificationFiles: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return model.IdentificationType == "USTaxID" || model.IdentificationType == "SocialSecurityNumber" || model.IdentificationType == "Foreign";
                })
            },
            SampleMediaKitFiles: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return model.UrlSpecifiedInForm == "false" || model.UrlSpecifiedInForm == false;
                })
            },
            InfoFromName: { required },
            InfoFromTitle: { required },
            InfoFromPhone: {
                required,
                minLength: minLength(10)
            },
            InfoFromSourceIsVerbal: {
                required: requiredIf(function () {
                    return !this.MediaVendor.InfoFromSourceIsWritten;
                }),
            },
            InfoFromSourceIsWritten: {
                required: requiredIf(function () {
                    return !this.MediaVendor.InfoFromSourceIsVerbal;
                }),
            },
            InfoFromSourceVerbal: {
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsVerbal == true || model.InfoFromSourceIsVerbal == "true";
                }),
            },
            InfoFromSourceWritten: {
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsWritten == true || model.InfoFromSourceIsWritten == "true";
                }),
            },
            SetupInfoSource: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                return model.InfoFromSourceIsWritten == "true" || model.InfoFromSourceIsWritten == true;
            })
            },
            FormerEmployeeReimbursement: {
                required: required
            },
            VendorRemittanceName: {
                required: required
            },
            RemittanceCountry: {
                required: required
            },
            StreetAddressLine1: {
                required: required
            },
            RemittanceCity: {
                required: requiredIf(function (model) {
                    return model.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
            },
            RemittanceState: {
                required: requiredIf(function (model) {
                    return model.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
            },
            RemittanceZipCode: {
                required: requiredIf(function (model) {
                    return model.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
                numeric,
            },
        }
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    watch: {
        "MediaVendor.MediaType": function(val) {
            if (val || !val) {
                this.MediaVendor.StreetAddressLine1 = '';
                this.MediaVendor.VendorRemittanceName = '';
            }
        },
        "MediaVendor.IsEditDataLoaded": function (val) {
            if (this.IsEdit == true && val == true) {
                if (this.IsSampleMediaKitFilesLoadedInEdit == false) {
                    this.ManuallyUploadFilesSampleMedia(this.MediaVendor.SampleMediaKitFiles);
                    this.$set(this.MediaVendor, "SampleMediaKitFiles", this.GetFilesObject());
                }
                if (this.IsIdentificationFilesLoadedInEdit == false) {
                    this.ManuallyUploadFilesIdentification(this.MediaVendor.IdentificationFiles);
                    this.$set(this.MediaVendor, "IdentificationFiles", this.GetIDFilesObject());
                }
                if (this.IsWrittenFilesLoadedInEdit == false) {
                    this.ManuallyUploadFilesWritten(this.MediaVendor.SetupInfoSource);
                    this.$set(this.MediaVendor, "SetupInfoSource", this.GetFilesObjectSource());
                }
            }
        },
        "MediaVendor.UrlSpecifiedInForm": function () {
            if (!this.IsUrlNotSpecified) {
                this.$refs.myVueDropzone.dropzone.removeAllFiles();
            }
        },
        "MediaVendor.InfoFromSourceIsVerbal": function (val) {
            if(val == false || val == 'false') {
                this.MediaVendor.InfoFromSourceVerbal=null;
            }
        },
        "MediaVendor.InfoFromSourceIsWritten": function (val) {
            if(val == false ||  val == 'false') {
                this.MediaVendor.InfoFromSourceWritten=null;
            }
        },
        "MediaVendor.IdentificationType": function (val) {
            if (val) {
                let identificationIndex = this.Identificationnumberslist.findIndex(newVal => {
                    return newVal.value == val;
                })
                if (identificationIndex > -1) {
                    this.$set(this.MediaVendor, "IdentificationName", this.Identificationnumberslist[identificationIndex].label);
                }
                else {
                    this.$set(this.MediaVendor, "IdentificationName", '');
                }
            }
        },
        "MediaVendor.RemittanceCountry": function (val) {
            if (val) {
                this.MediaVendor.RemittanceCity = '';
                this.MediaVendor.RemittanceState = '';
                this.MediaVendor.RemittanceZipCode = '';
            }
        },
    },
    computed: {
        IsVerbalOrWrittenChecked() {
            return this.InfoFromSourceDisplayWritten || this.InfoFromSourceDisplayVerbal;
        },
        charLimit() {
            return this.MediaVendor.IsDigitalMedia === 'true' && this.MediaVendor.MediaType ? '20' : '20' || this.MediaVendor.IsDigitalMedia === 'true' ? '30' : '20';
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('mediavendor', {
            MediaVendor: 'MediaVendor',
        }),
        IsUrlNotSpecified() {
            return this.MediaVendor.UrlSpecifiedInForm == "false" || this.MediaVendor.UrlSpecifiedInForm == false;
        },
        IsUSTaxIDSelected() {
            return this.MediaVendor.IdentificationType == 'USTaxID';
        },
        IsSocialSecurityNumberSelected() {
            return this.MediaVendor.IdentificationType == 'SocialSecurityNumber';
        },
        AttachmentLabel() {
            return this.MediaVendor.IdentificationType == 'SocialSecurityNumber' || this.MediaVendor.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        InfoFromSourceDisplayVerbal() {
            return this.MediaVendor.InfoFromSourceIsVerbal;
        },
        InfoFromSourceDisplayWritten() {
            return this.MediaVendor.InfoFromSourceIsWritten;
        },
        IsCountryUSA() {
            return this.MediaVendor.RemittanceCountry != null ? this.MediaVendor.RemittanceCountry == this.CountryUnitedStatesValue : null;
        },
    },
    mounted() {
        this.LoadDropdownValues();
    },
    methods: {
        IDNumberchange() {
            this.MediaVendor.USTaxID = "";
            this.MediaVendor.SocialSecurityNumber = "";
            this.MediaVendor.Foreign = "";
            this.$refs.identificationDropzone.removeAllFiles()
        },
        mountedIdentitificationDropzone() {
            if (this.MediaVendor.ID > 0 && this.IsIdentificationFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesIdentification(this.MediaVendor.IdentificationFiles);
                this.$set(this.MediaVendor, "IdentificationFiles", this.GetIDFilesObject());
            }
        },
        mountedSampleMediaKitDropzone() {
            if (this.MediaVendor.IsEditDataLoaded == true && this.IsSampleMediaKitFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesSampleMedia(this.MediaVendor.SampleMediaKitFiles);
                this.$set(this.MediaVendor, "SampleMediaKitFiles", this.GetFilesObject());
            }
        },
        mountedWrittenDropzone() {
            if (this.MediaVendor.IsEditDataLoaded == true && this.IsEdit && this.IsWrittenFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesWritten(this.MediaVendor.SetupInfoSource);
                this.$set(this.MediaVendor, "SetupInfoSource", this.GetFilesObjectSource());
                this.WrittenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
            }
        },
        ManuallyUploadFilesIdentification(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.$refs.identificationDropzone.manuallyAddFile(file, url);
                });

            }
            this.IsIdentificationFilesLoadedInEdit = true;
        },
        ManuallyUploadFilesSampleMedia(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.$refs.myVueDropzone.manuallyAddFile(file, url);
                });
            }
            this.IsSampleMediaKitFilesLoadedInEdit = true;
        },
        ManuallyUploadFilesWritten(files) {
            if (this.$refs.DropzoneWrittenSource != null) {
                if (files != null && files.length > 0) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.DropzoneWrittenSource.manuallyAddFile(file, url);
                    });
                }
                this.IsWrittenFilesLoadedInEdit = true;
            }
        },
        IsNumeric(prop) {
            let props = [{
                name: 'USTaxID',
                type: 'numeric'
            }, {
                name: 'SocialSecurityNumber',
                type: 'numeric'
            }, {
                name: 'Foreign',
                type: 'text'
            }];
            let index = props.findIndex(val => val.name == prop);
            if (index > -1) {
                return props[index].type == 'numeric';
            } else {
                return false;
            }
        },
        SetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$touch();

        },
        ResetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$reset();
        },
        UploadedToDropzone() {
            this.$set(this.MediaVendor, "SampleMediaKitFiles", this.GetFilesObject());
        },
        RemovedFromDropzone() {
            this.$set(this.MediaVendor, "SampleMediaKitFiles", this.GetFilesObject());
        },
        IdentificationUploadedToDropzone() {
            this.$set(this.MediaVendor, "IdentificationFiles", this.GetIDFilesObject());
        },
        IdentificationRemovedFromDropzone() {
            this.$set(this.MediaVendor, "IdentificationFiles", this.GetIDFilesObject());
        },
        UploadedtoDropzoneSource() {
            this.WrittenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
            this.$set(this.MediaVendor, "SetupInfoSource", this.GetFilesObjectSource());
        },
        RemovedFromDropzoneSource() {
            this.WrittenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
            this.$set(this.MediaVendor, "SetupInfoSource", this.GetFilesObjectSource());
        },
        GetFilesObject() {
            let files = [];
            if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        GetIDFilesObject() {
            let files = [];
            if (this.$refs.identificationDropzone.dropzone.files && this.$refs.identificationDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.identificationDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        GetFilesObjectSource() {
            let files = [];
            if (typeof this.$refs.DropzoneWrittenSource != "undefined" &&
                typeof this.$refs.DropzoneWrittenSource.dropzone != "undefined" &&
                this.$refs.DropzoneWrittenSource.dropzone.files.length > 0) {
                let filesExists = this.$refs.DropzoneWrittenSource.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        GetMaxLength(field) {
            if (field == 'USTaxID') {
                return 10;
            } else if (field == 'Foreign') {
                return -1;
            } else {
                return 12;
            }
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Relationship Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Relationship Info"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Credit Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Credit Info"
                });
            }
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        LoadDropdownValues() {
            this.LoadStates();
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.CountryList = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.CountryList.findIndex(
                            (i) => i.label == this.CountryUnitedStatesValue
                        );
                        let indexCanada = this.CountryList.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.CountryList.unshift(
                                this.CountryList.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
                        this.CountryList.forEach(element => { element.value = element.label;});
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.StateList = res.data;
                    this.StateList.forEach(element => { element.value = element.label;});
                })
                .catch((err) => {
                    alert(err);
                });
        },
        ValidateZipCode() {
            if (this.IsCountryUSA && this.MediaVendor.RemittanceZipCode.length != 0 && this.MediaVendor.RemittanceZipCode.length < 5) {
                this.validationtextbox = true;
            } else {
                this.validationtextbox = false;
            }
        },
    }
}
</script>

<style>
fieldset.form-group:not(.custom-radio)>legend {
    padding-left: 0px !important;
}

/*file upload starts*/

.customdropzonearea .customdropzone {
    width: 411px !important;
    height: 100% !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    /* margin-left: 97px !important; */
    margin-top: 14px !important;
    width: 237px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    color: #a2a9ad !important;
}

.draganddropspan {
    color: #00a1d2;
}

/*file upload ends*/
</style>
